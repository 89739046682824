import { useState, useEffect } from "react";

export default function App() {
  //
  const [opts, setOpts] = useState([]);
  const [imgB64, setImgB64] = useState('');
  const [ws, setWs] = useState();

  useEffect(() => {
    console.log("skandjkasvdjavdgjhv")
    // Criar uma nova instância do WebSocket
    const newWs = new WebSocket("ws://localhost:8765");
    // Event handler for when the WebSocket connection is established
    newWs.addEventListener('open', function (event) {
      console.log('Connection established with the WebSocket server');
      // Send a message to the server
    });
    // Event handler for errors
    newWs.addEventListener('error', function (event) {
      console.error('An error occurred:', event);
    });

    // Event handler for when the connection is closed
    newWs.addEventListener('close', function (event) {
      console.log('Connection closed');
    });
    setWs(newWs)
  }, [])
  //
  function scan() {
    ws.onmessage = (event) => {
       if (event.data) {
        const jsonData = JSON.parse(event.data)
        const result = jsonData.map((v, i) => ({
          id: i,
          name: v,
        }))
        setOpts(result);
      }
    }

    ws.send('rota1');
  }

  function print() {
    ws.onmessage = (event) => {
      setImgB64(`data:image/jpeg;base64,${event.data}`)
    }

    ws.send(opts[0].name);
  }

  return (
    <div className="App">
      <button onClick={() => scan()}>Scan</button>
      <br />
      <br />
      <select>
        {opts.map((v) => (
          <option key={v.id} value={v.id}>{v.name}</option>
        ))}
      </select>
      <br />
      <br />
      <button onClick={() => print()}>Print</button>
      <br />
      <br />
      <img src={imgB64} />
    </div>
  );
}
